import React from "react";
import { LineWave } from "react-loader-spinner";
import { motion } from "framer-motion";
const LoadingSpinner = () => {
  return (
    <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
    >
      <LineWave color="#282828" />
    </motion.div>
  );
};

export default LoadingSpinner;
