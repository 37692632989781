import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import ReceiptCard from "../components/ReceiptCard";
import LoadingSpinner from "../components/LoadingSpinner";
const Receipts = () => {
  const [receipts, setReceipts] = useState([]);
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${api_url}/receipts/`)
      .then((res) => {
        setLoading(false);
        setReceipts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function DeleteReceipts() {
    const data = {
      receipts: selectedReceipts.map((receipt) => receipt.id),
    };
    axios
      .post(`${api_url}/receipts/`, data)
      .then((res) => {
        setReceipts(
          receipts.filter(
            (receipt) =>
              !selectedReceipts
                .map((receipt) => receipt.id)
                .includes(receipt.id)
          )
        );
        setSelectedReceipts([]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      {selectedReceipts.length > 0 && (
        <button
          onClick={DeleteReceipts}
          className="bg-red-500 text-white  px-4 py-2 block ml-auto rounded-md mb-8 shadow-md hover:bg-red-600 transition-colors ease-in-out duration-300"
        >
          Șterge bonurile selectate ({selectedReceipts.length})
        </button>
      )}
      <div className="grid grid-cols-8 lg:grid-cols-6 md:hidden sm:hidden font-semibold text-sm px-4 py-1">
        <div className="col-span-2">Număr bon</div>
        <div className="col-span-3">Plătitor, adresa</div>
        <div className="col-span-2 lg:hidden md:hidden sm:hidden">Contacte</div>
        <div>Total</div>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex flex-col gap-y-4">
          {receipts.map((receipt) => (
            <ReceiptCard
              key={receipt.id}
              selectedReceipts={selectedReceipts}
              setSelectedReceipts={setSelectedReceipts}
              receipt={receipt}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Receipts;
