import React, { useEffect, useState } from "react";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import CreateReceipt from "./screens/CreateReceipt";
import Products from "./screens/Products";
import Receipts from "./screens/Receipts";
import Receipt from "./screens/Receipt";
import burger from "./static/images/burger.svg";
import axios from "axios";
import { api_url } from "./constants";
import LoadingSpinner from "./components/LoadingSpinner";
import exit from "./static/images/exit.svg";
function App() {
  const url = useLocation().pathname;
  const [pageName, setPageName] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [code, setCode] = useState("");
  const [access, setAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const local_code = localStorage.getItem("code");
    setLoading(true);
    if (local_code) {
      axios
        .post(`${api_url}/verify/`, { code: local_code })
        .then((res) => {
          setAccess(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(false);
    }
    switch (url) {
      case "/products":
        setPageName("Produse");
        break;
      case "/new-receipt":
        setPageName("Creează bon");
        break;
      case "/receipts":
        setPageName("Bonuri");
        break;
      default:
        setPageName("");
        break;
    }
  }, [url]);
  return (
    <div className="relative w-[100vw] min-h-screen">
      {(loading && <LoadingSpinner />) ||
        (access && (
          <div className="flex">
            <div
              className={
                "w-[15%] md:w-[30%] lg:w-[25%] bg-background sm:absolute sm:left-0 sm:overflow-x-hidden sm:top-0 sm:z-50 transition-all ease-in-out duration-300 min-h-screen " +
                (expanded ? "sm:w-full" : "sm:w-0")
              }
            >
              <div className="px-6 py-12 sm:py-6 flex flex-col gap-y-4">
                <img
                  onClick={() => setExpanded((expanded) => !expanded)}
                  src={burger}
                  className="w-5 h-5 cursor-pointer ml-auto sm:block hidden"
                  alt="burger"
                />
                <Link
                  onClick={() => setExpanded((expanded) => !expanded)}
                  to="/products"
                  className={
                    "font-bold text-lg px-2 py-1 transition-colors ease-in-out duration-300 uppercase" +
                    (url === "/products" ? " bg-black/10" : " bg-black/5")
                  }
                >
                  Produse
                </Link>
                <Link
                  onClick={() => setExpanded((expanded) => !expanded)}
                  to="/new-receipt"
                  className={
                    "font-bold text-lg px-2 py-1 transition-colors ease-in-out duration-300 uppercase" +
                    (url === "/new-receipt" ? " bg-black/10" : " bg-black/5")
                  }
                >
                  Creează bon
                </Link>
                <Link
                  onClick={() => setExpanded((expanded) => !expanded)}
                  to="/receipts"
                  className={
                    "font-bold text-lg px-2 py-1 transition-colors ease-in-out duration-300 uppercase" +
                    (url === "/receipts" ? " bg-black/10" : " bg-black/5")
                  }
                >
                  Bonuri
                </Link>
              </div>
            </div>
            <div className="w-[85%] sm:w-full lg:w-[75%] md:w-[70%]">
              <div className="bg-background h-12 flex items-center px-6">
                <img
                  onClick={() => setExpanded((expanded) => !expanded)}
                  src={burger}
                  className="w-5 h-5 cursor-pointer mr-4 sm:block hidden"
                  alt="burger"
                />
                <div className="flex justify-between w-full items-center">
                  <h1 className="text-lg font-bold">{pageName}</h1>
                  <img
                    src={exit}
                    className="w-5 h-5 cursor-pointer"
                    alt="exit"
                    onClick={() => {
                      localStorage.removeItem("code");
                      setAccess(false);
                    }}
                  />
                </div>
              </div>
              <div className="p-8 relative">
                <Routes>
                  {/* <Route path="/" element={<CreateReceipt />} /> */}
                  <Route path="/new-receipt" element={<CreateReceipt />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/receipts" element={<Receipts />} />
                  <Route path="/receipts/:id" element={<Receipt />} />
                </Routes>
              </div>
            </div>
          </div>
        )) || (
          <form className="flex flex-col w-80 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 gap-y-2">
            <input
              type="password"
              className="w-full block"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            ></input>
            <button
              onClick={(e) => {
                e.preventDefault();
                axios
                  .post(`${api_url}/verify/`, { code: code })
                  .then((res) => {
                    localStorage.setItem("code", code);
                    setAccess(true);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
              className="bg-black/80 w-full md:order-1 text-white px-4 py-2 block ml-auto rounded-md h-fit shadow-md hover:bg-black/90 transition-colors ease-in-out duration-300"
            >
              Accesează
            </button>
          </form>
        )}
    </div>
  );
}

export default App;
