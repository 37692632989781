import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../constants";
import convertPythonDateTimetoDateTime from "../utils/ConvertPythonDateTimeToDateTime";
import LoadingSpinner from "../components/LoadingSpinner";
const Receipt = () => {
  const { id } = useParams();
  const [receipt, setReceipt] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios.get(`${api_url}/receipts/${id}/`).then((res) => {
      setReceipt(res.data);
      axios.get(`${api_url}/receipts/${id}/products/`).then((res) => {
        setProducts(res.data);
        setLoading(false);
      });
    });
  }, [id]);

  function DownloadReceipt() {
    axios
      .get(`${api_url}/receipts/${id}/download/`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "cont_de_plata.docx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  return (
    (loading && <LoadingSpinner />) || (
      <div>
        <div className="flex md:flex-col justify-between sm:flex-col gap-y-4">
          <div className="flex flex-col md:order-2 sm:order-2 gap-y-2">
            <div className="flex gap-x-2">
              <div className="font-semibold">Număr bon:</div>
              {receipt.receipt_no} din{" "}
              {receipt.created_at &&
                convertPythonDateTimetoDateTime(receipt.created_at)}
            </div>
            <div className="flex gap-x-2">
              <div className="font-semibold">Plătitor, adresa:</div>
              {receipt.payer}, {receipt.payer_address}
            </div>
            <div className="flex gap-x-2">
              <div className="font-semibold">Contacte:</div>
              {receipt.payer_contacts}
            </div>

            <div className="flex gap-x-2">
              <div className="font-semibold">TVA:</div>
              {receipt.tva}% ({(receipt.total * receipt.tva) / 100} MDL)
            </div>
            <div className="flex gap-x-2">
              <div className="font-semibold">Total:</div>
              {receipt.total - (receipt.total * receipt.tva) / 100} +{" "}
              {(receipt.total * receipt.tva) / 100} = {receipt.total} MDL
            </div>
          </div>
          <button
            onClick={DownloadReceipt}
            className="bg-black/80 sm:order-1 md:w-full md:order-1 text-white px-4 py-2 block ml-auto rounded-md h-fit shadow-md hover:bg-black/90 transition-colors ease-in-out duration-300"
          >
            Descarcă cont de plată
          </button>
        </div>
        <div>
          <h2 className="mt-8 text-lg uppercase font-semibold">
            Produsele din bon
          </h2>
          <div className="grid grid-cols-12 md:hidden sm:hidden font-medium text-sm px-4 mt-2">
            <div className="col-span-4 lg:col-span-3">Nume produs</div>
            <div className="col-span-2">Untiate de măsură</div>
            <div className="col-span-2">Cantitate</div>

            <div>Preț</div>
          </div>
          <div className="flex flex-col gap-y-2">
            {products.map((product) => (
              <div className="grid grid-cols-12 sm:grid-cols-1 gap-y-2 md:grid-cols-2 bg-background px-4 py-2 rounded-md drop-shadow-md">
                <div className="col-span-4 md:col-span-2 sm:col-span-1 lg:col-span-3">
                  <span className="font-semibold md:inline sm:inline hidden">
                    Nume produs:
                  </span>{" "}
                  {product.name}
                </div>
                <div className="col-span-2 md:col-span-1 sm:col-span-1">
                  <span className="font-semibold md:inline sm:inline hidden">
                    Untiate de măsură:
                  </span>{" "}
                  {product.packaging} ({product.packaging_unit})
                </div>
                <div className="col-span-2 md:col-span-1 sm:col-span-1">
                  <span className="font-semibold md:inline sm:inline hidden">
                    Cantitate:
                  </span>{" "}
                  {product.quantity}
                </div>

                <div>
                  <span className="font-semibold md:inline sm:inline hidden">
                    Preț:
                  </span>{" "}
                  {product.price} MDL
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default Receipt;
