import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url } from "../constants";
import Notifications from "../components/Notifications";
import { motion } from "framer-motion";
import LoadingSpinner from "../components/LoadingSpinner";
const Products = () => {
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState("");
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${api_url}/products/`)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setProducts(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  function CreateProduct(e) {
    e.preventDefault();

    if (name === "" || unit === "" || price === "") {
      setShow(true);
      setNotification("Toate câmpurile sunt obligatorii!");
      return;
    }

    if (isNaN(Number(price))) {
      setShow(true);
      setNotification("Prețul trebuie să fie un număr!");
      return;
    }

    const data = {
      name,
      packaging_unit: unit,
      price,
    };
    axios
      .post(`${api_url}/product/`, data)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setShow(true);
        setNotification("Produs adăugat cu succes!");
      })
      .catch((error) => {
        console.log(error.response);
        setShow(true);
        setNotification("Eroare la adăugarea produsului!");
      });
  }

  function DeleteProducts() {
    axios
      .post(`${api_url}/products/`, {
        products: selectedProducts,
      })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setShow(true);
        setNotification("Produse șterse cu succes!");
        setSelectedProducts([]);
        setProducts((products) =>
          products.filter((product) => !selectedProducts.includes(product.id))
        );
      })
      .catch((error) => {
        console.log(error.response);
        setShow(true);
        setNotification("Eroare la ștergerea produselor!");
      });
  }

  return (
    <div>
      <form
        onSubmit={CreateProduct}
        className="flex flex-wrap gap-y-4 sm:flex-col  gap-x-4"
      >
        <input
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Nume produs"
        />
        <input
          onChange={(e) => setUnit(e.target.value)}
          value={unit}
          placeholder="Unitate ambalaj"
        />
        <input
          type="number"
          onChange={(e) => setPrice(e.target.value)}
          value={price}
          placeholder="Preț produs"
        />
        <button className="bg-black text-white sm:w-full px-4 py-2  rounded-md">
          Adaugă produs nou
        </button>
      </form>
      {selectedProducts.length > 0 && (
        <button
          onClick={DeleteProducts}
          className="bg-red-500 text-white px-4 py-2 block ml-auto rounded-md mb-8 shadow-md hover:bg-red-600 transition-colors ease-in-out duration-300"
        >
          Șterge produsele selectate ({selectedProducts.length})
        </button>
      )}
      <Notifications setShow={setShow} show={show}>
        {notification}
      </Notifications>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-3 gap-4 mt-8">
          {products.map((product, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white drop-shadow-lg p-4 flex flex-col"
            >
              <div className="flex items-center gap-x-2">
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedProducts((selectedProducts) => [
                        ...selectedProducts,
                        product.id,
                      ]);
                    } else {
                      setSelectedProducts((selectedProducts) =>
                        selectedProducts.filter((id) => id !== product.id)
                      );
                    }
                  }}
                  type="checkbox"
                />{" "}
                <div className="font-bold">Nume:</div> {product.name}
              </div>
              <div className="flex gap-x-2 text-sm">
                <div className="font-semibold">Unitate ambalaj:</div>
                {product.packaging_unit}
              </div>
              <div className="flex gap-x-2 text-sm">
                <div className="font-semibold">Preț:</div>
                {product.price}
              </div>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Products;
