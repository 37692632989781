import React, { useState } from "react";
import { motion } from "framer-motion";
import convertPythonDateTimetoDateTime from "../utils/ConvertPythonDateTimeToDateTime";
import expand from "../static/images/expand.svg";
import { Link } from "react-router-dom";

const ReceiptCard = ({ receipt, selectedReceipts, setSelectedReceipts }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <motion.div className="">
      <div className="grid grid-cols-8 md:grid-cols-1 sm:grid-cols-1 gap-y-2 md:text-sm lg:grid-cols-6 bg-background drop-shadow-md px-4 py-2 rounded-md">
        <div className="col-span-2 flex  items-center gap-x-2">
          <input
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedReceipts([...selectedReceipts, receipt]);
              } else {
                setSelectedReceipts(
                  selectedReceipts.filter(
                    (selectedReceipt) => selectedReceipt.id !== receipt.id
                  )
                );
              }
            }}
            value={selectedReceipts.includes(receipt)}
            type="checkbox"
            className="h-5 w-5 "
          ></input>
          {receipt.receipt_no} din{" "}
          {convertPythonDateTimetoDateTime(receipt.created_at)}
        </div>
        <div className="col-span-3">
          <span className="hidden md:inline sm:inline font-semibold">
            Plătitor, adresa:{" "}
          </span>
          {receipt.payer}, {receipt.payer_address}
        </div>
        <div className="col-span-2 lg:hidden">
          <span className="hidden md:inline sm:inline font-semibold">
            Contacte:{" "}
          </span>
          {receipt.payer_contacts}
        </div>
        <div className="flex justify-between items-center">
          {receipt.total} MDL
          <Link to={"/receipts/" + receipt.id} className="pr-2 pl-4">
            <img
              src={expand}
              alt="expand"
              className="w-4 cursor-pointer h-4 ml-auto"
            />
          </Link>
        </div>
      </div>
      <motion.div
        className="flex items-center gap-x-4 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
        initial={{ height: 0 }}
        animate={{ height: expanded ? "auto" : 0 }}
        transition={{ duration: 0.2 }}
      ></motion.div>
    </motion.div>
  );
};

export default ReceiptCard;
