import React, { useEffect } from "react";
import { motion } from "framer-motion";
const Notifications = ({ setShow, show, children }) => {
  useEffect(() => {
    if (show)
      setTimeout(() => {
        setShow(false);
      }, 3000);
  }, [show]);

  return (
    <motion.div
      className="fixed bottom-8 right-0 bg-black/70 z-50 drop-shadow-lg text-white py-2 px-8 rounded-md"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: show ? 1 : 0, x: show ? -20 : 100 }}
      transition={{
        duration: 0.3,
        ease: "easeInOut",
        delay: 0.2,
        bounce: 1,
        stiffness: 100,
      }}
    >
      {children}
    </motion.div>
  );
};

export default Notifications;
