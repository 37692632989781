import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url } from "../constants";
import ArrowRight from "../static/images/arrow-right.svg";
import Notifications from "../components/Notifications";
import { useNavigate } from "react-router-dom";
const CreateReceipt = () => {
  const [receiptNumber, setReceiptNumber] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [payerName, setPayerName] = useState("");
  const [payerAddress, setPayerAddress] = useState("");
  const [payerContacts, setPayerContacts] = useState("");
  const [isReceiptSending, setIsReceiptSending] = useState(false);

  const [tva, setTva] = useState(20);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState("");
  const [productsAdded, setProductsAdded] = useState([]);
  const [receiptSent, setReceiptSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${api_url}/products/`)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((error) => {
        console.log(error.response);
      });

    axios
      .get(`${api_url}/receipts/number/`)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setReceiptNumber(res.data.receipt_no);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  useEffect(() => {
    setTotalPrice(
      selectedProducts.reduce((acc, curr) => {
        if (isNaN(curr.price) || curr.price === "") return acc;
        if (isNaN(curr.quantity) || curr.quantity === "") return acc;
        return acc + parseFloat(curr.price) * parseFloat(curr.quantity);
      }, 0)
    );
  }, [selectedProducts]);

  function CreateReceipt(e) {
    e.preventDefault();
    if (
      payerAddress === "" ||
      payerName === "" ||
      payerContacts === "" ||
      tva === "" ||
      receiptNumber === ""
    ) {
      setShow(true);
      setNotification("Toate câmpurile sunt obligatorii!");
      return;
    }
    selectedProducts.forEach((product) => {
      if (
        isNaN(product.quantity) ||
        isNaN(product.packaging_unit) ||
        isNaN(product.price) ||
        product.quantity === "" ||
        product.packaging_unit === "" ||
        product.price === ""
      ) {
        setShow(true);
        setNotification("Toate câmpurile sunt obligatorii!");
        return;
      }
    });

    const data = {
      receipt_no: receiptNumber,
      payer_address: payerAddress,
      payer: payerName,
      payer_contacts: payerContacts,
      tva,
      total: totalPrice,
    };
    setIsReceiptSending(true);
    axios
      .post(`${api_url}/receipt/`, data)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setReceiptSent(true);
        VerifyReceipt();
        const receipt_id = res.data;
        let i = 0;
        VerifyReceipt(i, receipt_id);

        selectedProducts.forEach((product) => {
          axios
            .post(`${api_url}/receipt/product/`, {
              receipt: res.data,
              name: product.name,
              price: product.price,
              packaging_unit: product.unit,
              quantity: product.quantity,
            })
            .then((res) => {
              i++;
              console.log(res);
              VerifyReceipt(i, receipt_id);
              console.log(res.data);
              setShow(true);
              setNotification("Produse adăugate cu succes!");
            })
            .catch((error) => {
              console.log(error.response);
              setShow(true);
              setNotification("Eroare la adăugarea produselor!");
              axios
                .delete(`${api_url}/receipts/${receipt_id}`)
                .then((res) => {})
                .catch((error) => {
                  console.log(error.response);
                });
              setIsReceiptSending(false);
              return;
            });
        });
      })
      .catch((error) => {
        console.log(error);
        setShow(true);
        setNotification("Eroare la adăugarea bonului!");
        setIsReceiptSending(false);
      });
  }

  function VerifyReceipt(i = 0, receipt_id = "") {
    if (i === selectedProducts.length) {
      setTimeout(() => {
        setIsReceiptSending(false);
        navigate("/receipts/" + receipt_id);
      }, 2000);
    }
  }

  return (
    <div>
      <form onSubmit={CreateReceipt}>
        <Notifications setShow={setShow} show={show}>
          {notification}
        </Notifications>
        <div className="flex justify-between flex-wrap">
          <div className="flex gap-x-4 flex-wrap gap-y-4">
            <input
              value={payerName}
              onChange={(e) => setPayerName(e.target.value)}
              type="text"
              placeholder="Nume plătitor"
            />
            <input
              value={payerAddress}
              onChange={(e) => setPayerAddress(e.target.value)}
              type="text"
              placeholder="Adresa livrării"
            />
            <input
              value={payerContacts}
              onChange={(e) => setPayerContacts(e.target.value)}
              type="text"
              placeholder="Contacte plătitor"
            />
            <input
              value={tva}
              onChange={(e) => {
                setTva(e.target.value);
              }}
              type="number"
              placeholder="TVA (%)"
              defaultValue={"12"}
            />
            <input
              type="number"
              value={receiptNumber}
              onChange={(e) => setReceiptNumber(e.target.value)}
              placeholder="Numarul bonului"
            />
            <button
              disabled={isReceiptSending}
              type="submit"
              className="bg-black sm:w-full font-medium px-8 text-white py-2 rounded-md"
            >
              {isReceiptSending ? "Se trimite bonul..." : "Creează bon nou"}
            </button>
          </div>
        </div>
        <div>
          <h2 className="font-semibold text-lg mt-4">
            Preț produse: {totalPrice.toFixed(2)} MDL, TVA:{" "}
            {((totalPrice * parseInt(tva)) / 100).toFixed(2)} MDL, Total:{" "}
            {(totalPrice + (totalPrice * parseInt(tva)) / 100).toFixed(2)} MDL
          </h2>
        </div>
        <div className="grid grid-cols-12 w-full gap-y-8 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 2xl:gap-x-8 xl:gap-x-8 mt-8">
          <div className="col-span-8 lg:w-full sm:w-full md:w-full lg:order-2 md:order-2 sm:order-2">
            <h2 className="font-semibold text-lg">Produse selectate</h2>
            <div className="flex flex-col gap-y-2 p-4 bg-white drop-shadow-lg">
              {selectedProducts.map((product) => (
                <div
                  key={product.id}
                  className="relative md:grid-cols-2 text-sm gap-y-4  sm:grid-cols-1 gap-x-4 grid grid-cols-4 items-center border-b-solid border-b-1 font-semibold border-b-black/40 py-2"
                >
                  <div className="">
                    {product.name} - {product.price} MDL
                  </div>

                  <input
                    value={product.price}
                    onChange={(e) => {
                      setSelectedProducts(
                        selectedProducts.map((p) =>
                          p.id === product.id
                            ? { ...p, price: e.target.value }
                            : p
                        )
                      );
                    }}
                    type="number"
                    placeholder="Preț unitate"
                    className="text-center w-full block "
                  />

                  <input
                    value={product.quantity}
                    onChange={(e) => {
                      setSelectedProducts(
                        selectedProducts.map((p) =>
                          p.id === product.id
                            ? { ...p, quantity: e.target.value }
                            : p
                        )
                      );
                    }}
                    type="number"
                    placeholder="Cantitate"
                    className="text-center w-full block "
                  />
                  <input
                    value={product.unit}
                    onChange={(e) => {
                      setSelectedProducts(
                        selectedProducts.map((p) =>
                          p.id === product.id
                            ? { ...p, unit: e.target.value }
                            : p
                        )
                      );
                    }}
                    placeholder="Unitate de măsură"
                    className="text-center w-4/5 block "
                  />

                  <img
                    onClick={() => {
                      setProducts([...products, product]);
                      setSelectedProducts(
                        selectedProducts.filter((p) => p.id !== product.id)
                      );
                    }}
                    src={ArrowRight}
                    className="absolute top-1/2 sm:top-4 md:top-4 right-2 transform cursor-pointer -translate-y-1/2 w-4 h-4"
                  />
                </div>
              ))}
              <div className="relative md:grid-cols-2 sm:grid-cols-1 gap-y-4 text-sm gap-x-4 grid grid-cols-4 items-center border-b-solid border-b-1 font-semibold border-b-black/40 py-2">
                <div className="">
                  Total: {selectedProducts.length} tipuri de produse
                </div>
                <div className=" md:text-left sm:text-left text-center md:col-span-1"></div>

                <div className="md:text-left sm:text-left text-center md:col-span-1">
                  <span className="lg:hidden xl:hidden 2xl:hidden font-semibold">
                    Cantitate:{" "}
                  </span>
                  {selectedProducts.reduce((acc, curr) => {
                    if (isNaN(curr.quantity) || curr.quantity === "")
                      return acc;
                    return acc + parseFloat(curr.quantity);
                  }, 0)}{" "}
                  buc
                </div>
                <div className=" md:text-left sm:text-left text-center md:col-span-1"></div>
              </div>
            </div>
          </div>
          <div className="col-span-4 lg:order-1 md:order-1 sm:order-1 lg:w-full sm:w-full md:w-full">
            <h2 className="font-semibold text-lg w-full">
              Produse disponibile
            </h2>
            <div className="flex flex-col gap-y-2 p-4 bg-white drop-shadow-lg">
              {products.map((product) => (
                <div
                  key={product.id}
                  onClick={() => {
                    setSelectedProducts([...selectedProducts, product]);
                    setProducts(products.filter((p) => p.id !== product.id));
                  }}
                  className="hover:-translate-x-1 ease-in-out duration-300 transition-transform border-b-solid border-b-1 font-semibold cursor-pointer border-b-black/40 py-1"
                >
                  {product.name}- {product.price} MDL
                </div>
              ))}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateReceipt;
